import React from "react"
import Layout from "../../components/Layout"
import { Container, Col, Row } from "react-bootstrap"
import PartnerCarousel from "../../components/PartnerCarousel"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faShieldAlt,
  faServer,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons"
import PeaceOfMindGuarantee from "../../components/PeaceOfMindGuarantee"
import Services from "../../components/Services"
import EducationOffers from "../../components/education/EducationOffers"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import SectionTitle from "../../components/SectionTitle"
import IconBoxList from "../../components/BoxList"

export default function IT() {
  const data = useStaticQuery(graphql`
    query educationIT {
      laptopsAndTablets: file(
        relativePath: { eq: "services/AdobeStock_274709086.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      storage: file(
        relativePath: { eq: "services/AdobeStock_278784366.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ITNetworks: file(
        relativePath: { eq: "services/AdobeStock_119201395.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Software: file(
        relativePath: { eq: "services/AdobeStock_317550082.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Furniture: file(
        relativePath: { eq: "services/AdobeStock_48949475.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Printers: file(
        relativePath: { eq: "services/AdobeStock_255540819.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ITLab: file(relativePath: { eq: "education/AdobeStock_215722491.jpeg" }) {
        childImageSharp {
          fixed(width: 350) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Laptops & Tablets",
      description: "iMacs, Macbooks, Google Chromebooks and PCs",
      image: data.laptopsAndTablets.childImageSharp.fixed,
    },
    {
      title: "Storage",
      description: "Backup, NAS, Airport Time Capsule",
      image: data.storage.childImageSharp.fixed,
    },
    {
      title: "IT Networks",
      description: "Servers, Storage, Cabling, Switches and Web Filtering",
      image: data.ITNetworks.childImageSharp.fixed,
    },
    {
      title: "Software",
      description:
        "Mobile Device Management, Microsoft, Operating Systems, Adobe, User Monitoring",
      image: data.Software.childImageSharp.fixed,
    },
    {
      title: "Furniture",
      description:
        "Desking, IT Suites, Classroom Refurbishment, Storage Cupboards, Benching, Lockers",
      image: data.Furniture.childImageSharp.fixed,
    },
    {
      title: "Printers",
      description:
        "Mono Laser Printers, Colour Laser Printers, Multifunction Printers, Inkjet Printer",
      image: data.Printers.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="education">
      <SEO title="Compulease · IT Education" />
      <EducationOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              subtitle="Keeping your school in touch with the latest technology as it
                  develops"
              title="IT Leasing for Schools"
              description={
                <>
                  <h4>Classroom Installations</h4>
                  <Col lg={{ span: "10", offset: "1" }}>
                    <p>
                      Equipping your school with the latest technology doesn’t
                      have to be as expensive as you think. You can update a
                      whole IT suite including 30 PCs or iMacs, an interactive
                      touchscreen, and a teacher’s laptop all in one go!
                    </p>
                    <p>
                      Our education specialists are highly trained to provide
                      impartial advice on implementation and installation of all
                      IT related equipment which will invariably save you money.
                    </p>
                  </Col>
                </>
              }
            />
            <Row>
              <Col md="8">
                <h4>IT</h4>
                <p>
                  We appreciate every school is unique and has its own specific
                  needs. So whether your requirement is for a single computer in
                  the Administration Department, a fully integrated network
                  including a server or a 1,500 Chromebook roll-out in a
                  secondary school, Compulease™ will design a specific “bespoke”
                  solution for you.
                </p>
                <p>
                  All IT solutions are supported with a 3 year manufacturer’s
                  warranty to provide you with complete peace of mind.
                </p>
              </Col>
              <Col md="4">
                <Img
                  fixed={data.ITLab.childImageSharp.fixed}
                  className="img-fluid"
                  alt="IT School Lab"
                />
              </Col>
            </Row>
            <IconBoxList
              boxSizeLarge
              noBorder
              items={[
                {
                  title: "Servers",
                  desc:
                    "Compulease™ supply a complete range of “tailor-made” server network solutions including towers, rack servers with enclosures, switches, backup, computers, full installation, cabling and configuration.",
                  icon: <FontAwesomeIcon icon={faServer} />,
                },
                {
                  title: "Warranty, maintenance & support",
                  desc:
                    "All PCs are supplied with a manufacturer’s 3 year next business day onsite warranty, in addition to the technical support. Full maintenance bespoke packages are available upon request.",
                  icon: <FontAwesomeIcon icon={faShieldAlt} />,
                },
                {
                  title: "Install to desk & imaging facility",
                  desc:
                    "When requested, Compulease™ can “install to desk” or “image” your new laptops and computers with your chosen configuration, so they’re ready to use and connectable to your network as you take them out of the box, saving you valuable time!",
                  icon: <FontAwesomeIcon icon={faUserClock} />,
                },
              ]}
            />
            <PeaceOfMindGuarantee />
          </Col>
        </Row>
        <Row>
          <Col>
            <Services title="Solutions we provide" services={services} />
          </Col>
        </Row>
      </Container>
      <PartnerCarousel type="education" />
    </Layout>
  )
}
